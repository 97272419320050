import Render from "./render.js";
import Api from "./api.js";
import IntegrationAnglerAI from "../integrations/anglerai.js";

class Outpost {
    constructor(options) {
        const defaults = {}
        this.options = options && typeof options === "object" ? this.#extendDefaults(defaults, options) : defaults;
        this.integrations = {};
    }

    #extendDefaults(defaults, properties) {
        Object.keys(properties).forEach(property => {
            if (properties.hasOwnProperty(property)) {
                defaults[property] = properties[property];
            }
        });
        return defaults;
    }

    #loadIntegrations(integrations) {
        Object.keys(integrations).forEach(name => {
            if (name === 'anglerai') {
                const integration = new IntegrationAnglerAI(this.options, integrations[name].params)
                integration.init()

                this.integrations[name] = integration
            }
        });
    }

    init() {
        const api = new Api(this.options)
        api.request('', {}, (response) => {
            // render visual modules
            const render = new Render(this.options, response.site)
            render.render()

            // load integrations
            if (response.site.integrations) {
                this.#loadIntegrations(response.site.integrations);
            }
        })
    }

    logEvent(eventName, eventData) {
        Object.keys(this.integrations).forEach(name => {
            const integration = this.integrations[name];
            if (typeof integration[eventName] === "function") {
                integration[eventName](eventData);
            }
        });
    }

}

export default Outpost
