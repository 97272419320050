import RenderBase from "./render-base.js";
import Api from "./api.js";
import MicroModal from "../modal/index.js";

class RenderPdfDownload extends RenderBase {

    constructor(options, site) {
        super(options, site, {});
        this.options = options
    }

    render() {
        document.querySelectorAll(`[outpost-download-pdf]`).forEach(btn => {
            const pdfGuid = btn.getAttribute('outpost-download-pdf');
            btn.addEventListener('click', () => {
                const api = new Api(this.options)
                api.request('/pdf-download', {pdf: {guid: pdfGuid}}, (response) => {
                    this.#process(response)
                })
                return false;
            })
        });
    }

    #process(response) {
        if (response.url) {
            // show pdf file
            window.open(response.url)
        } else {
            Object.keys(response.popups).forEach(key => {
                const popup = response.popups[key];
                this.loadAssets(key, popup)

                const selector = `outpost-${popup.className}-${popup.guid}`;
                document.activeElement.blur();
                MicroModal.show(selector);
            });

            this.attachSubscriptionFormEvents();
        }
    }

}

export default RenderPdfDownload
