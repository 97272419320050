

class IntegrationBase {

    constructor(options, params) {
        this.options = options
        this.params = params
    }

}

export default IntegrationBase
