import {isContactFormPage, loadCss, loadFonts} from "./helpers.js";
import RenderButton from "./render-button.js";
import RenderCta from "./render-cta.js";
import RenderPdfDownload from "./render-pdf-download.js";
import RenderPaywall from "./render-paywall.js";
import RenderContactForm from "./render-contact-form.js";

class Render {

    constructor(options, data) {
        this.options = options
        this.data = data
    }

    render() {
        if (this.data.css) {
            loadCss("outpost-pub-style", this.data.css);
        }
        if (this.data.fonts) {
            loadFonts(this.data.fonts);
        }

        // old style code, deprecated and removed soon
        const buttonRender = new RenderButton(this.options, this.data.site, this.data.buttons, this.data.member)
        buttonRender.render()

        if (this.data.paywall) {
            const paywallRender = new RenderPaywall(this.options, this.data.site, this.data.paywall)
            paywallRender.render()
        }

        if (this.data.boxes) {
            const ctaRender = new RenderCta(this.options, this.data.site, this.data.boxes)

            if (this.data.paywall) {
                ctaRender.disablePopups()
            }

            ctaRender.render()
        }

        const pdfDownloadRender = new RenderPdfDownload(this.options, this.data.site)
        pdfDownloadRender.render()

        if (isContactFormPage()) {
            const contactFormRender = new RenderContactForm(this.options, this.data.site, this.data.member)
            contactFormRender.render()
        }
    }


}

export default Render
