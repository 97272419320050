import RenderBase from "./render-base.js";

class RenderButton extends RenderBase {

    render() {
        this.renderManualButtons();

        if (this.data) {
            this.renderCTAButtons(this.data);
        }
    }

    renderManualButtons() {
        const options = this.options;
        const isPaidMember = options.memberId && options.memberPaid === 'true' && options.memberPlans.length;

        if (isPaidMember) {
            this.outpostCheckDonationButtons('data-btn-donation-paidmembers');
            this.outpostShowForPaidMembersButtons();
        }

        this.outpostCheckDonationButtons('data-btn-donation-all');
        this.renderManualTierButton();
    }

    renderCTAButtons(buttons) {
        Object.keys(buttons).forEach(key => {
            const button = buttons[key];

            if (!button.url) return;

            document.querySelectorAll(`[data-btn-${key}]`).forEach(btnContainer => {
                btnContainer.innerHTML = '';

                const divWrapper = document.createElement('div');
                divWrapper.className = 'text-center';

                const newBtn = this.createCTAButton(button);

                divWrapper.appendChild(newBtn);
                btnContainer.appendChild(divWrapper);
            });
        });
    }

    createCTAButton(button) {
        const target = button.target || '';
        const className = button.class || 'btn btn-primary';
        const newBtn = document.createElement('a');

        newBtn.className = className;
        newBtn.setAttribute('target', target);
        newBtn.setAttribute('href', button.url);
        newBtn.innerHTML = button.text;
        newBtn.style.visibility = 'visible';

        return newBtn;
    }

    outpostCheckDonationButtons(selector) {
        const options = this.options;
        const url = `${options.apiDomain}/ghost-integration/${options.apiKey}/tips/${options.memberId}`;
        const text = (btnContainer) => btnContainer.getAttribute('data-btn-text') || 'Tip Jar';

        document.querySelectorAll(`[${selector}]`).forEach(btnContainer => {
            btnContainer.innerHTML = '';

            const divWrapper = document.createElement('div');
            divWrapper.className = 'text-center';
            const newBtn = this.outpostCreateDonationButton({ url, text: text(btnContainer) });

            divWrapper.appendChild(newBtn);
            btnContainer.appendChild(divWrapper);
        });
    }

    outpostShowForPaidMembersButtons() {
        document.querySelectorAll('.visible-paid-only').forEach(btnContainer => {
            btnContainer.style.visibility = 'visible';
            btnContainer.style.display = 'block';
        });
    }

    outpostCreateDonationButton(params) {
        const defaultMemberText = this.getDefaultMemberText();
        const text = params.text || defaultMemberText;
        const target = params.target || '';
        const className = params.class || 'btn btn-primary';
        const url = params.url || '';

        const newBtn = document.createElement('a');
        newBtn.className = className;
        newBtn.setAttribute('target', target);
        newBtn.setAttribute('href', url);
        newBtn.innerHTML = text;
        newBtn.style.visibility = 'visible';

        return newBtn;
    }

    getDefaultMemberText() {
        const options = this.options;

        let defaultMemberText = `Subscribe To ${options.siteName}`;
        if (options.memberId) {
            defaultMemberText = options.memberPaid ? 'Become a Supporter' : 'Become A Paid Subscriber For Full Access';
        }

        return defaultMemberText;
    }

    renderManualTierButton() {
        let member = this.member
        if (!member) {
            member = {
                'tier_id': 'unknown',
            }
        }

        document.querySelectorAll(`[data-btn-tier]`).forEach(btnContainer => {
            let a = btnContainer.querySelector('a');
            if (a && member) {
                const settings = JSON.parse(decodeURIComponent(a.getAttribute('data-tier-settings')))
                settings.forEach((row) => {
                    if (row.tierId === member.tier_id) {
                        btnContainer.style.visibility = 'visible';
                        btnContainer.style.display = 'block';

                        a.setAttribute('href', row.url)

                        let label = row.label;
                        label = label.replaceAll('{TIER_NAME}', row.tierName)

                        a.innerText = label
                    }
                })
            }
        });

    }

}

export default RenderButton
