import IntegrationBase from "./base.js";
import {loadJs} from "../js/helpers.js";

class IntegrationAnglerAI extends IntegrationBase {

    init() {
        const params = this.params
        loadJs(['https://static.getangler.ai/dist/analytics/_latest.min.js'], () => {
            window.anglerai.init({
                workspaceId: params.workspaceId,
                token: params.tokenPixel,
            })

            this.#setupPageAction();
        })
    }

    logEvent = function(eventData) {
        const options = this.options;
        if (eventData.data && options.memberEmail) {
            eventData.data['customer'] = {
                'email': options.memberEmail
            }
        }
        window.anglerai.sendEvent(eventData)
    }

    productViewed() {
        this.logEvent({eventName: "product_viewed", data: {}})
    }

    productSelected(params) {
        let data = {
            'cartLine' : {
                'merchandise' : {
                    'id': params.id,
                    'price': {
                        'id': params.id,
                        'amount': params.price,
                    },
                    'product': {
                        'id': params.id,
                    }
                }
            }
        }
        this.logEvent({eventName: "product_added_to_cart", data: data})
    }

    pageViewed() {
        this.logEvent({eventName: "page_viewed", data: {}})
    }

    eventSignup() {
        const options = this.options;
        if (options.memberEmail && options.memberId) {
            const eventData = {
                eventName: "form_submitted",
                eventId: `signup_${options.memberId}`,
                data: {
                    customer: {
                        'email': options.memberEmail
                    }
                }
            }
            window.anglerai.sendEvent(eventData)
        }
    }

    eventSignin() {
        this.logEvent({eventName: "custom_event", data: {}, customEventName: "signed_in"})
    }

    #setupPageAction() {
        const options = this.options;

        // log page view event
        if (options.post) {
            this.pageViewed()
        }

        let params = new URLSearchParams(document.location.search);
        let action = params.get('action');
        const path = window.location.pathname;

        // log some page events
        if (action) {
            if (action === 'signin') {
                this.eventSignin()
            } else if (action === 'signup') {
                this.eventSignup()
            }
        } else {
            if (path === '/membership/') {
                this.productViewed()
            }
        }
    }

}

export default IntegrationAnglerAI
