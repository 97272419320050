import RenderCta from "./render-cta.js";
import MicroModal from "../modal/index.js";

class RenderPaywall extends RenderCta {

    #renderPaywallPopup(key, box) {
        const selector = `outpost-${key}-${box.guid}`;
        const config = {
            disableScroll: true,
            disableFocus: true,
            disableEsc: true,
        };

        document.activeElement.blur();
        MicroModal.show(selector, config);
    }

    #renderPaywallInPost(key, box) {
        const displayParagraphs = 3;
        let paragraphs = this.getPostContentItems(box)

        if (paragraphs.length < displayParagraphs) return;

        const newElement = document.createElement('div');
        newElement.className = `outpost-pub-container outpost-${box.className}`;
        newElement.setAttribute('data-box-' + key, '');
        newElement.style.visibility = 'visible';
        newElement.innerHTML = box.html

        // show only first 2 paragraphs and add CTA container
        paragraphs.forEach((el, index) => {
            if (index === displayParagraphs) {
                // add faded class
                el.parentNode.classList.add('outpost-cta-content-fading')
                // display CTA box
                el.parentNode.parentNode.append(newElement);
            } else if (index > displayParagraphs) {
                // hide other paragraphs
                el.remove();
            }
        })
    }

    render() {
        const box = this.data;
        const key = box.type
        this.loadAssets(key, box);

        if (key.includes('-popup')) {
            this.#renderPaywallPopup(key, box);
        } else {
            this.#renderPaywallInPost(key, box);
        }

        this.attachSubscriptionFormEvents();
    }

}

export default RenderPaywall
